import { useEffect, useRef, useState } from "react";

const LazyLoadVideo = ({ src, type, preview }: any) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsIntersecting(true);
        observer.disconnect();
      }
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div ref={videoRef} style={{ minHeight: "200px" }}>
      {isIntersecting && (
        <video controls preload="metadata" className="trailer" poster={preview}>
          <source src={src} type={type} />
        </video>
      )}
    </div>
  );
};

export default LazyLoadVideo;
