import { useRef } from "react";
import "../styles/Navigation.scss";

const Navigation = ({ onButtonClick, language }: any) => {
  const changePage = (event: any) => {
    const buttonId = (event.target as HTMLButtonElement).id;
    // playSound();
    onButtonClick(parseInt(buttonId));
  };

  const clickSound = useRef<any>(null);
  // const playSound = () => {
  //   if (clickSound.current) {
  //     clickSound.current.volume = 0.8;
  //     clickSound.current.play();
  //   }
  // };

  return (
    <div className="Navigation">
      <div className="Nav_body">
        <button id="1" onClick={changePage}>
          {language === "eng" ? "About Us" : "Про нас"}
        </button>
        <button id="2" onClick={changePage}>
          {language === "eng" ? "Movies" : "Фільми"}
        </button>
        <button id="3" onClick={changePage}>
          {language === "eng" ? "TV Shows" : "Серіали"}
        </button>
        <button id="4" onClick={changePage}>
          {language === "eng" ? "Documentaries" : "Документальні"}
        </button>
        <button id="5" onClick={changePage}>
          {language === "eng" ? "Development" : "У виробництві"}
        </button>
        <button id="6" onClick={changePage}>
          {language === "eng" ? "Distribution" : "Дистрибуція"}
        </button>
        <button id="7" onClick={changePage}>
          {language === "eng" ? "Partners" : "Партнери"}
        </button>
      </div>
      <audio ref={clickSound} src={`${process.env.PUBLIC_URL}/sounds/buttonClickSound.mp3`} />
    </div>
  );
};

export default Navigation;
