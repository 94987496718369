import Layout from "../Layout";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";
import "../styles/Carousel.scss";
import "swiper/css";
import "swiper/css/effect-coverflow";
import documentary from "../data/documentary.json";
import documentaryPage from "../data/documentaryPage.json";

const Documental = ({ showModalCinema, language }: any) => {
  const changePage = (data: any) => {
    showModalCinema(documentary.documentary[data - 1]);
  };

  return (
    <Layout>
      <div className="Documental">
        {language === "eng" ? (
          <h1 className="Title">Documentaries</h1>
        ) : (
          <h1 className="Title">Документальні Фільми</h1>
        )}
        <Swiper
          effect={"coverflow"}
          centeredSlides={true}
          initialSlide={1}
          direction={"horizontal"}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 5,
            stretch: 10,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          className="Carousel"
          modules={[EffectCoverflow]}
          slideToClickedSlide={true}
          lazyPreloadPrevNext={2}
        >
          {documentaryPage.documentary.map((movie, index) => (
            <SwiperSlide key={index}>
              <img
                src={process.env.PUBLIC_URL + movie.poster}
                alt={movie.nameUkr}
                className="Poster_Image"
                onClick={() => {
                  changePage(movie.id);
                }}
              />
              <div className="SlideText">
                {language === "eng" ? <h5>{movie.nameEng}</h5> : <h5>{movie.nameUkr}</h5>}
                {language === "eng" ? <h5>{movie.genreEng}</h5> : <h5>{movie.genreUkr}</h5>}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Layout>
  );
};

export default Documental;
