import ClockIcon from "../icons/ClockIcon";
import ScreenwiterIcon from "../icons/ScreenwiterIcon";
import LazyLoadVideo from "./LazyLoadVideo";

const ModalDevelopment = ({ data, language }: any) => {
  return (
    <div className="Modal_data">
      <div className="Modal_sections">
        <div className="Modal_left_section">
          {data.trailer !== "-" ? (
            <LazyLoadVideo src={process.env.PUBLIC_URL + data.trailer} type="video/mp4" preview={data.preview} />
          ) : (
            <img src={process.env.PUBLIC_URL + data.poster} alt="poster" className="ModalPhoto"></img>
          )}
        </div>
        <div className="Modal_right_section">
          <h1 className="Modal_title">{language === "eng" ? data.nameEng : data.nameUkr}</h1>
          <div className="UnderTitle_data">
            {language === "eng" ? (
              <h5 className="genre">{data.genreEng}</h5>
            ) : (
              <h5 className="genre">{data.genreUkr}</h5>
            )}
            <div className="year">
              <ClockIcon></ClockIcon>
              {language === "eng" ? <h5>{data.countEng}</h5> : <h5>{data.countUkr}</h5>}
            </div>
          </div>
          <div className="Director">
            <img src={process.env.PUBLIC_URL + "/icons8-film-reel-100.png"} alt="" />
            {language === "eng" ? <h5>{data.directorEng}</h5> : <h5>{data.directorUkr}</h5>}
          </div>
          {data.producersEng !== "-" ? (
            <div className="Producer">
              <img src={process.env.PUBLIC_URL + "/icons8-documentary-90.png"} alt="" />
              {language === "eng" ? <h5>{data.producersEng}</h5> : <h5>{data.producersUkr}</h5>}
            </div>
          ) : (
            <></>
          )}
          {data.screenwritersUkr !== "-" ? (
            <div className="Producer">
              <ScreenwiterIcon></ScreenwiterIcon>
              {language === "eng" ? <h5>{data.screenwritersEng}</h5> : <h5>{data.screenwritersUkr}</h5>}
            </div>
          ) : (
            <></>
          )}
          {data.festivalsUkr[0] !== "-" ? (
            <div>
              {language === "eng" ? (
                <div className="Festivals">
                  <img src={process.env.PUBLIC_URL + "/icons8-gold-medal-100.png"} alt="" />
                  <div className="Festivals_data">
                    {data.festivalsEng.map((festival: any, index: any) => (
                      <h5 key={index}>{festival}</h5>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="Festivals">
                  <img src={process.env.PUBLIC_URL + "/icons8-gold-medal-100.png"} alt="" />
                  <div className="Festivals_data">
                    {data.festivalsUkr.map((festival: any, index: any) => (
                      <h5 key={index}>{festival}</h5>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="Modal_sections">
            {language === "eng" ? (
              <div className="Modal_left_section">
                <p>{data.descriptionEng}</p>
              </div>
            ) : (
              <div className="Modal_left_section">
                <p>{data.descriptionUkr}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDevelopment;
