import "../styles/Home.scss";

const HomeSmall = ({ language }: any) => {
  return (
    <div className="HomeSmall">
      <div className="Home_title">
        <img src="./Logo.webp" alt="" className="LogoSmall" />
      </div>
    </div>
  );
};

export default HomeSmall;
