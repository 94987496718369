import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "./styles/styles.scss";
import "./styles/App.scss";
import { Mousewheel } from "swiper/modules";
import { windowTransform } from "./services/windowTransform";
import { useEffect, useRef, useState } from "react";
import { Cloudinary } from "@cloudinary/url-gen";
import Home from "./components/Home";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import About from "./components/About";
import Cinema from "./components/Cinema";
import TVShow from "./components/TVShow";
import Documental from "./components/Documental";
import Progress from "./components/Progress";
import Distribution from "./components/Distribution";
import Partners from "./components/Partners";
import Modal from "./components/Modal";
import HomeSmall from "./components/HomeSmall";
import ModalDevelopment from "./components/ModalDevelopment";
import CrossIcon from "./icons/CrossIcon";

const FullPage: React.FC = () => {
  const [isLoad, setIsLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const swiperRef = useRef<any>(null);
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("language");
    return savedLanguage ? savedLanguage : "ukr";
  });
  const [modal, setModal] = useState(false);
  const [currentMovie, setCurrentMovie] = useState([]);
  const [modalDevelopment, setModalDevelopment] = useState(false);
  const [currentDevelopment, setCurrentDevelopment] = useState([]);
  let swipeNext = false;

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dz171kjxb",
    },
  });

  const showModalCinema = (data: any) => {
    setCurrentMovie(data);
    setModal(true);
  };
  const showModalDevelopment = (data: any) => {
    setCurrentDevelopment(data);
    setModalDevelopment(true);
  };

  const handlePageChange = (swiper: any) => {
    if (swiper.activeIndex > 0 && swiper.activeIndex < 7) {
      scrollToTop();
      swipeNext = false;
      setTimeout(() => {
        // playSound();
        document.body.style.overflow = "hidden";
      }, 200);
    } else if (swiper.activeIndex === 0) {
      scrollToTop();
      document.body.style.overflow = "hidden";
      swipeNext = false;
    } else if (swiper.activeIndex === 7) {
      scrollToTop();
      setTimeout(() => {
        swipeNext = true;
      }, 100);

      setTimeout(() => {
        document.body.style.overflow = "auto";
      }, 300);
    }
    showHeader(swiper.activeIndex);
    setCurrentPage(swiper.activeIndex);
  };

  const handleValueChange = (page: number) => {
    if (swiperRef.current !== null) {
      swiperRef.current?.swiper.slideTo(page, 700);
      showHeader(1);
    }
  };

  const closeModal = () => {
    setModal(false);
  };
  const closeModalDevelopment = () => {
    setModalDevelopment(false);
  };

  const showHeader = (number: any) => {
    const header = document.querySelector(".Header") as HTMLElement;
    const home = document.querySelector(".HomeSmall") as HTMLElement;
    const bottomNav = document.querySelector(".BottomNav") as HTMLElement;
    const footer = document.querySelector(".Footer") as HTMLElement;
    windowTransform(header, home, bottomNav, footer, number);
  };

  const changeLang = () => {
    if (language === "eng") {
      setLanguage("ukr");
      localStorage.setItem("language", "ukr");
    } else {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  let lastScrollY = window.scrollY;

  window.addEventListener("scroll", function (e: any) {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      if (swiperRef.current !== null) {
        swiperRef.current?.swiper.slideTo(0, 700);
        showHeader(0);
        scrollToTop();
      }
    }

    lastScrollY = currentScrollY;
  });

  let startY = 0;
  let isSwiping = false;

  window.addEventListener("touchstart", function (e) {
    startY = e.touches[0].clientY;
    isSwiping = true;
  });

  window.addEventListener("touchend", function (e) {
    if (!isSwiping) return;

    const endY = e.changedTouches[0].clientY;

    const threshold = 10;
    const deltaY = startY - endY;

    if (Math.abs(deltaY) > threshold) {
      if (deltaY > 0 && swipeNext === true) {
        if (swiperRef.current !== null) {
          swiperRef.current?.swiper.slideTo(0, 700);
          showHeader(0);
          scrollToTop();
          swipeNext = false;
        }
        startY = 0;
        isSwiping = false;
      }
    }

    isSwiping = false;
  });

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoad(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="AppTest">
        {/* <ParticlesBg></ParticlesBg> */}
        {/* <div className="Background">
          <div className="BackgroundBody">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div> */}
        {isLoad || !isVideoLoaded ? (
          <div className="Loader">
            <img src="./Logo.webp" alt="" className="Logo" />
          </div>
        ) : (
          <></>
        )}
        <video autoPlay muted loop preload="auto" className="App_video" onCanPlayThrough={handleVideoLoaded}>
          <source
            src="https://res.cloudinary.com/dz171kjxb/video/upload/f_auto:video,q_auto/pgowx2gean8khtenr6n0"
            type="video/mp4"
          />
        </video>
        {modal ? (
          <div className="Modal">
            <div className="Modal_header">
              <button onClick={closeModal}>
                <CrossIcon></CrossIcon>
              </button>
            </div>
            <div className="Modal_body">
              <Modal data={currentMovie} language={language}></Modal>
            </div>
          </div>
        ) : (
          <></>
        )}
        {modalDevelopment ? (
          <div className="Modal">
            <div className="Modal_header">
              <button onClick={closeModalDevelopment}>
                <CrossIcon></CrossIcon>
              </button>
            </div>
            <div className="Modal_body">
              <ModalDevelopment data={currentDevelopment} language={language}></ModalDevelopment>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="Navigation">
          <Navigation onButtonClick={handleValueChange} language={language}></Navigation>
        </div>
        <div className="Header" style={{ display: "none" }}>
          <HomeSmall language={language}></HomeSmall>
        </div>
        <div className="BottomNav" style={{ display: "none" }}>
          <Footer onButtonClick={handleValueChange} language={language}></Footer>
        </div>
        <button className="Language" onClick={changeLang}>
          <img src={process.env.PUBLIC_URL + "/icons8-globe-96.png"} alt="language" />
        </button>
        <Swiper
          ref={swiperRef}
          direction={"vertical"}
          className="MainSlider"
          modules={[Mousewheel]}
          mousewheel={{
            enabled: true,
            releaseOnEdges: true,
            sensitivity: 0.1,
          }}
          speed={700}
          slidesPerView={1}
          initialSlide={currentPage}
          onSlideChange={handlePageChange}
          loop={false}
          spaceBetween={0}
          resistanceRatio={0}
          lazyPreloadPrevNext={1}
        >
          <SwiperSlide>
            <Home language={language}></Home>
          </SwiperSlide>
          <SwiperSlide>
            <About language={language}></About>
          </SwiperSlide>
          <SwiperSlide>
            <Cinema showModalCinema={showModalCinema} language={language}></Cinema>
          </SwiperSlide>
          <SwiperSlide>
            <TVShow showModalCinema={showModalCinema} language={language}></TVShow>
          </SwiperSlide>
          <SwiperSlide>
            <Documental showModalCinema={showModalCinema} language={language}></Documental>
          </SwiperSlide>
          <SwiperSlide>
            <Progress showModalDevelopment={showModalDevelopment} language={language}></Progress>
          </SwiperSlide>
          <SwiperSlide>
            <Distribution showModalCinema={showModalCinema} language={language}></Distribution>
          </SwiperSlide>
          <SwiperSlide>
            <Partners language={language}></Partners>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="SpaceHome"></div>
    </>
  );
};

export default FullPage;
