const ClockIcon = () => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="none" />
      <circle cx="12" cy="12" r="9" stroke="#3faac1" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 6V12L16.5 16.5" stroke="#3faac1" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default ClockIcon;
