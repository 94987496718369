export function windowTransform(
  header: HTMLElement,
  home: HTMLElement,
  bottomNav: HTMLElement,
  footer: HTMLElement,
  number: any
) {
  if (number === 0) {
    home.classList.remove("opEnter");
    home.classList.add("opExit");
    footer.classList.remove("opEnter");
    footer.classList.add("opExit");
    setTimeout(() => {
      header.style.display = "none";
      bottomNav.style.display = "none";
    }, 400);
  } else if (number > 0) {
    setTimeout(() => {
      header.style.display = "block";
      bottomNav.style.display = "block";
    }, 250);

    setTimeout(() => {
      home.classList.remove("opExit");
      home.classList.add("opEnter");
      footer.classList.remove("opExit");
      footer.classList.add("opEnter");
      header.style.position = "fixed";
      header.style.zIndex = "2000";
    }, 300);
  }
}
