import "../styles/Home.scss";

const Home = ({ language }: any) => {
  return (
    <div className="Home">
      <div className="Home_title">
        <img src="./Logo.webp" alt="" className="Logo" />
        {/* <p className="Home_text">
          {language === "eng"
            ? "We develop concepts for films, TV series, advertising campaigns, and music videos, forging our projects from ideas to distribution."
            : " Ми розробляємо концепти кінофільмів, серіалів, рекламних кампаній, музичних кліпів. Втілюємо наші проєкти від ідеї до дистрибуції."}
        </p> */}
      </div>
    </div>
  );
};

export default Home;
