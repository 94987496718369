import { useState } from "react";
import ClockIcon from "../icons/ClockIcon";
import ScreenwiterIcon from "../icons/ScreenwiterIcon";
import "../styles/Modal.scss";
import LazyLoadVideo from "./LazyLoadVideo";

const Modal = ({ data, language }: any) => {
  const [isPH1oLoaded, setIsPH1Loaded] = useState(false);
  const [isPH2oLoaded, setIsPH2Loaded] = useState(false);
  const [isPH3oLoaded, setIsPH3Loaded] = useState(false);

  const handleIm1Loaded = () => {
    setIsPH1Loaded(true);
  };
  const handleIm2Loaded = () => {
    setIsPH2Loaded(true);
  };
  const handleIm3Loaded = () => {
    setIsPH3Loaded(true);
  };
  return (
    <div className="Modal_data">
      <div className="Modal_sections">
        <div className="Modal_left_section">
          <LazyLoadVideo src={process.env.PUBLIC_URL + data.trailer} type="video/mp4" preview={data.preview} />
          {/* <iframe
            className="trailer"
            title="Video"
            height="100%"
            width="100%;"
            src="https://embed.wave.video/zknF2UBdp6yti4Zb"
            allow=" fullscreen"
          ></iframe> */}
        </div>
        <div className="Modal_right_section">
          <h1 className="Modal_title">{language === "eng" ? data.nameEng : data.nameUkr}</h1>
          <div className="UnderTitle_data">
            {language === "eng" ? (
              <h5 className="genre">{data.genreEng}</h5>
            ) : (
              <h5 className="genre">{data.genreUkr}</h5>
            )}

            <div className="year">
              <img src={process.env.PUBLIC_URL + "/icons8-calendar-96.png"} alt="" />
              <h5>{data.year}</h5>
            </div>
            <div className="year">
              <ClockIcon></ClockIcon>
              {language === "eng" ? <h5>{data.countEng}</h5> : <h5>{data.countUkr}</h5>}
            </div>
          </div>
          <div className="Director">
            <img src={process.env.PUBLIC_URL + "/icons8-film-reel-100.png"} alt="" />
            {language === "eng" ? <h5>{data.directorEng}</h5> : <h5>{data.directorUkr}</h5>}
          </div>
          {data.producersEng && data.producersEng !== "-" ? (
            <div className="Producer">
              <img src={process.env.PUBLIC_URL + "/icons8-documentary-90.png"} alt="" />
              {language === "eng" ? <h5>{data.producersEng}</h5> : <h5>{data.producersUkr}</h5>}
            </div>
          ) : (
            <></>
          )}
          {data.screenwritersUkr && data.screenwritersUkr !== "-" ? (
            <div className="Producer">
              <ScreenwiterIcon></ScreenwiterIcon>
              {language === "eng" ? <h5>{data.screenwritersEng}</h5> : <h5>{data.screenwritersUkr}</h5>}
            </div>
          ) : (
            <></>
          )}
          {data.StarsEng && (
            <>
              {language === "eng" ? (
                <div className="Stars">
                  <img src={process.env.PUBLIC_URL + "/icons8-multiple-stars-96.png"} alt="" />

                  {data.StarsEng.map((name: any, index: any) => (
                    <h5 key={index}>{name}</h5>
                  ))}
                </div>
              ) : (
                <div className="Stars">
                  <img src={process.env.PUBLIC_URL + "/icons8-multiple-stars-96.png"} alt="" />

                  {data.StarsUkr.map((name: any, index: any) => (
                    <h5 key={index}>{name}</h5>
                  ))}
                </div>
              )}
            </>
          )}
          {data.festivalsUkr[0] !== "-" ? (
            <div>
              {language === "eng" ? (
                <div className="Festivals">
                  <img src={process.env.PUBLIC_URL + "/icons8-gold-medal-100.png"} alt="" />
                  <div className="Festivals_data">
                    {data.festivalsEng.map((festival: any, index: any) => (
                      <h5 key={index}>{festival}</h5>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="Festivals">
                  <img src={process.env.PUBLIC_URL + "/icons8-gold-medal-100.png"} alt="" />
                  <div className="Festivals_data">
                    {data.festivalsUkr.map((festival: any, index: any) => (
                      <h5 key={index}>{festival}</h5>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="Modal_sections">
        {language === "eng" ? (
          <div className="Modal_left_section">
            <p>{data.descriptionEng}</p>
          </div>
        ) : (
          <div className="Modal_left_section">
            <p>{data.descriptionUkr}</p>
          </div>
        )}

        <div className="Modal_right_section">
          <div className="Images">
            {!isPH1oLoaded && (
              <div className="ModalImageLoader">
                <div className="dot-spinner">
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                </div>
              </div>
            )}
            <img src={process.env.PUBLIC_URL + data.images[0]} alt="" onLoad={handleIm1Loaded} />
            {!isPH2oLoaded && (
              <div className="ModalImageLoader">
                <div className="dot-spinner">
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                </div>
              </div>
            )}
            <img src={process.env.PUBLIC_URL + data.images[1]} alt="" onLoad={handleIm2Loaded} />
            {!isPH3oLoaded && (
              <div className="ModalImageLoader">
                <div className="dot-spinner">
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                  <div className="dot-spinner__dot"></div>
                </div>
              </div>
            )}
            <img src={process.env.PUBLIC_URL + data.images[2]} alt="" onLoad={handleIm3Loaded} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
