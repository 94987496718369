import { useEffect, useRef, useState } from "react";
import "../styles/Footer.scss";

const Footer = ({ onButtonClick, language }: any) => {
  const [DropdownNav, setDropdownNav] = useState(false);
  const [DropdownContact, setDropdownContact] = useState(false);
  const [DropdownSoc, setDropdownSoc] = useState(false);
  const [marginRight, setMarginRight] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);

  const changePage = (event: any) => {
    const buttonId = (event.target as HTMLButtonElement).id;
    onButtonClick(parseInt(buttonId));
  };

  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setDropdownNav(false);
      setDropdownContact(false);
      setDropdownSoc(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
    document.addEventListener("touchend", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
      document.removeEventListener("touchend", handleOutsideClick);
    };
  }, []);

  return (
    <div className="Footer opExit" ref={menuRef}>
      <button
        onClick={() => {
          setMarginRight(250);
          setDropdownNav(!DropdownNav);
          setDropdownContact(false);
          setDropdownSoc(false);
        }}
      >
        <img width="50" height="50" src="https://img.icons8.com/material-rounded/96/505455/movie.png" alt="movie" />
      </button>
      <button
        onClick={() => {
          setMarginRight(0);
          setDropdownContact(!DropdownContact);
          setDropdownNav(false);
          setDropdownSoc(false);
        }}
      >
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/ios-filled/100/505455/apple-phone.png"
          alt="apple-phone"
        />
      </button>
      <button
        onClick={() => {
          setMarginRight(-250);
          setDropdownSoc(!DropdownSoc);
          setDropdownContact(false);
          setDropdownNav(false);
        }}
      >
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/ios-filled/100/505455/tiktok--v1.png"
          alt="tiktok--v1"
        />
      </button>
      {DropdownNav ? (
        <ul className="menu" style={{ marginRight: marginRight }}>
          <button id="1" onClick={changePage}>
            {language === "eng" ? "About Us" : "Про нас"}
          </button>
          <button id="2" onClick={changePage}>
            {language === "eng" ? "Movies" : "Фільми"}
          </button>
          <button id="3" onClick={changePage}>
            {language === "eng" ? "TV Shows" : "Серіали"}
          </button>
          <button id="4" onClick={changePage}>
            {language === "eng" ? "Documentaries" : "Документалки"}
          </button>
          <button id="5" onClick={changePage}>
            {language === "eng" ? "Development" : "У виробництві"}
          </button>
          <button id="6" onClick={changePage}>
            {language === "eng" ? "Distribution" : "Дистрибуція"}
          </button>
          <button id="7" onClick={changePage}>
            {language === "eng" ? "Partners" : "Партнери"}
          </button>
        </ul>
      ) : (
        <></>
      )}
      {DropdownContact ? (
        <ul className="menuCont" style={{ marginRight: marginRight }}>
          <a href="https://t.me/+380954679845" target="_blank" rel="noopener noreferrer" id="1">
            {language === "eng" ? "Telegram" : "Telegram"}
          </a>
          <a href="mailto:info@polygon.film">info@polygon.film</a>
          <a href="tel:+380954679845">+380954679845</a>
        </ul>
      ) : (
        <></>
      )}
      {DropdownSoc ? (
        <ul className="menuSoc" style={{ marginRight: marginRight }}>
          <a
            href="https://www.facebook.com/profile.php?id=100090019863402"
            target="_blank"
            rel="noopener noreferrer"
            id="1"
          >
            {language === "eng" ? "Facebook" : "Facebook"}
          </a>
          <a href="http://www.tiktok.com/@polygon_film" target="_blank" rel="noopener noreferrer" id="1">
            {language === "eng" ? "TikTok" : "TikTok"}
          </a>
          <a href="https://www.instagram.com/polygon_film/" target="_blank" rel="noopener noreferrer" id="1">
            {language === "eng" ? "Instagram" : "Instagram"}
          </a>
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Footer;
