import "./styles/Layout.scss";

const Layout = ({ children }: any) => {
  return (
    <div className="Layout">
      <div className="Layout_body">
        {children}
      </div>
    </div>
  );
};

export default Layout;
