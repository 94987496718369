import Layout from "../Layout";
import "../styles/About.scss";

const About = ({ language }: any) => {
  return (
    <Layout>
      <div className="About">
        {language === "eng" ? <h1 className="Title">About Us</h1> : <h1 className="Title">Про нас</h1>}
        <div className="AboutData">
          <div className="About_body">
            <div className="About_item">
              <img src={process.env.PUBLIC_URL + "https://i.postimg.cc/L88sYyTB/polygon-About-Photo1.webp"} alt="" />
              <p className="AboutItemTitle">{language === "eng" ? "Oksana Ivanyuk" : "Оксана Іванюк"}</p>
              <p className="AboutItemText">
                {language === "eng" ? "Creative producer, screenwriter" : "Креативна продюсерка, сценаристка"}
              </p>
            </div>
            <div className="About_item">
              <img src={process.env.PUBLIC_URL + "https://i.postimg.cc/Bn7nxx7W/polygon-About-Photo2.webp"} alt="" />
              <p className="AboutItemTitleRight">{language === "eng" ? "Myron Latyk" : "Мирон Латик"}</p>
              <p className="AboutItemTextRight">
                {language === "eng"
                  ? "CEO, producer, director, screenwriter"
                  : "Гендиректор, продюсер, режисер, сценарист"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
