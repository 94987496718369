import Layout from "../Layout";
import "../styles/Partners.scss";
import "../styles/Carousel.scss";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { useEffect, useState } from "react";

const Partners = ({ language }: any) => {
  const data = [
    "/partners/part1.webp",
    "/partners/part2.webp",
    "/partners/part3.webp",
    "/partners/part4.webp",
    "/partners/part5.webp",
    "/partners/part6.webp",
    "/partners/part7.webp",
    "/partners/part8.webp",
    "/partners/part9.webp",
    "/partners/part10.webp",
    "/partners/part11.webp",
  ];

  let [iter, setIter] = useState(0);
  const [currItem, setCurrItem] = useState(iter);

  useEffect(() => {
    setTimeout(() => {
      if (iter === data.length - 1) {
        setIter(0);
      } else {
        setIter(iter + 1);
      }
      setCurrItem(iter);
    }, 250);
  }, [currItem, iter, data.length]);

  return (
    <Layout>
      <div className="Partners">
        {language === "eng" ? <h1 className="Title">Partners</h1> : <h1 className="Title">Партнери</h1>}
        <div className="PartnersData">
          <div
            className="Partner_item"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + data[currItem]})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          ></div>
        </div>
      </div>
    </Layout>
  );
};

export default Partners;
